/* eslint-disable import/no-extraneous-dependencies */

"use client"

import React, {
  ChangeEventHandler,
  FC,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"

import Container from "components/Container"
import { H4, Serif2XL, XSMed } from "styles/Type"
import useSearch from "@/lib/useSearch"
import { usePathname, useRouter } from "next/navigation"
import Link from "next/link"
import clsx from "clsx"
import useAuth from "@/hooks/useAuth"

const PLACEHOLDERS = ["Met Gala", "Coachella", "NYFW"]

const NEW_PLACEHOLDER_WAIT = 5000
const NEW_SEGMENT_WAIT = 85
const FLASH_INTERVAL = 650

const LINKS = [
  {
    labell: "Discover",
    href: "/discover",
  },
  {
    labell: "Projects",
    href: "/projects",
  },
  {
    labell: "Directory",
    href: "/directory",
  },
]

const SHOWN_ENTITY_MAP = {
  artist: "Brand",
  brand: "Artist",
}

const SEARCH_LABEL_MAP = {
  artist: "SEARCH PARTNERS AND PROJECTS",
  brand: "SEARCH PARTNERS AND PROJECTS",
}

const PROFILE_TYPE_MAP = {
  artist: "brand",
  brand: "artist",
}
const PLACEHOLDERS_MAP = {
  directory: "Search",
  projects: "Search",
}

export const SearchHeader: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    children?: ReactNode
  }
> = ({ children }) => {
  const { query, setQuery } = useSearch()
  const { masquerade } = useAuth()
  const pathname = usePathname()
  const router = useRouter()
  const [activePlaceholder, setActivePlaceholder] = useState(0)
  const [activePlaceholderSegment, setActivePlaceholderSegment] = useState(
    PLACEHOLDERS[activePlaceholder]
  )
  const [flashActive, setFlashActive] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const activeTimeout = useRef<ReturnType<typeof setTimeout>>(null)

  const visiblePlaceholder = useDeferredValue(activePlaceholderSegment)
  const showFlashActive = useDeferredValue(flashActive)

  const advancePlaceholder = useCallback(() => {
    if (activePlaceholderSegment === PLACEHOLDERS[activePlaceholder]) {
      activeTimeout.current = setTimeout(() => {
        setActivePlaceholder((prev) => (prev + 1) % PLACEHOLDERS.length)
        setActivePlaceholderSegment("")
        setFlashActive(true)
        advancePlaceholder()
      }, NEW_PLACEHOLDER_WAIT)
      return
    }
    activeTimeout.current = setTimeout(() => {
      setActivePlaceholderSegment((prev) =>
        PLACEHOLDERS[activePlaceholder].slice(0, prev.length + 1)
      )
      advancePlaceholder()
    }, NEW_SEGMENT_WAIT)
  }, [activePlaceholder, activePlaceholderSegment])

  useEffect(() => {
    if (isFocused) {
      setActivePlaceholderSegment(PLACEHOLDERS[activePlaceholder])
      return () => {}
    }
    advancePlaceholder()
    const flashInterval = setInterval(
      () => setFlashActive((b) => !b),
      FLASH_INTERVAL
    )
    return () => {
      clearInterval(flashInterval)
      clearTimeout(activeTimeout.current)
    }
  }, [activePlaceholder, advancePlaceholder, isFocused])

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setQuery(e.currentTarget.value)
    },
    [setQuery]
  )

  useEffect(() => {
    if (pathname === "/search") {
      return
    }
    setQuery("")
  }, [pathname, setQuery])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (pathname !== "/search") {
      router.push("/search")
    }
  }

  const LABELS = useMemo(() => {
    return {
      discover: `Search ${SHOWN_ENTITY_MAP?.[masquerade?.type]}s & Projects`,
      search: SEARCH_LABEL_MAP?.[masquerade?.type],
      directory: `Search ${PROFILE_TYPE_MAP[masquerade?.type]}s`,
      projects: "Search projects",
    }
  }, [masquerade?.type])

  const label = useMemo(
    () => LABELS?.[pathname?.split("/")?.[1]],
    [LABELS, pathname]
  )
  const placeholder = useMemo(
    () => PLACEHOLDERS_MAP?.[pathname?.split("/")?.[1]],
    [pathname]
  )

  useEffect(() => {
    if (!placeholder) {
      setActivePlaceholderSegment("")
      setActivePlaceholder(0)
    }
  }, [placeholder])

  return (
    <section className="bg-offblack text-cream pt-[18px]">
      <XSMed
        as="nav"
        className="border-opacity-30 hidden lg:flex flex-grow !leading-[28px] pl-[24px] pb-[40px]"
      >
        <ul className="flex group">
          {LINKS.map(({ labell, href }) => (
            <li key={href} className="flex mr-[8px]">
              <Link
                onClick={() => setQuery("")}
                href={href}
                passHref
                className={clsx(
                  "hidden lg:flex flex-col justify-center p-4 py-[4px] px-[16px]",
                  !pathname.includes(href) &&
                    "opacity-50 !bg-[rgba(39,39,39,1)] !border-[rgba(39,39,39,1)] !text-cream",
                  "group-hover:opacity-50 group-hover:hover:opacity-100",
                  "transition duration-300 rounded-[68.754px] border border-[rgba(255,255,244,1)] bg-[rgba(255,255,244,1)] text-offblack tracking-[0.14px] font-semibold capitalize"
                )}
              >
                {labell}
              </Link>
            </li>
          ))}
        </ul>
      </XSMed>
      <Container>
        <form onSubmit={handleSubmit}>
          {pathname === "/projects" && (
            <input type="hidden" name="type" value="project" />
          )}
          <H4 as="label" className="uppercase mb-10  opacity-50">
            {label ?? "Search artists, brands & Projects"}
          </H4>
          <Serif2XL
            key="search-input"
            as="input"
            name="q"
            autoComplete="off"
            className="focus:outline-none mb-2.5 bg-transparent placeholder-white placeholder-opacity-20 w-full p-2 pt-[20px] -mx-2 text-[10.625rem]"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onSubmit={handleSubmit}
            onChange={onChange}
            value={query}
            placeholder={
              isFocused
                ? "Search"
                : [
                    placeholder || visiblePlaceholder,
                    !isFocused && showFlashActive ? "|" : "",
                  ].join("")
            }
          />
          {children}
        </form>
      </Container>
    </section>
  )
}
export default SearchHeader
