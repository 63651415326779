"use client"

import { usePathname, useRouter, useSearchParams } from "next/navigation"
import {
  createContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
} from "react"

const ctxSetState: Dispatch<SetStateAction<string>> = (s) => s

export const SearchContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  query: "",
  setQuery: ctxSetState,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const SearchContextProvider = ({ children }) => {
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()

  const currentPath = useRef<string>(pathname)
  const [query, setQuery] = useState(searchParams.get("search") || "")

  useEffect(() => {
    currentPath.current = pathname
  }, [pathname])

  useEffect(() => {
    if (!query) {
      return
    }
    const params = new URLSearchParams(Array.from(searchParams.entries()))
    params.set("q", query)

    if (currentPath.current.includes("project")) {
      params.set("type", "project")
    }

    router.push(`/search?${params.toString()}`)
  }, [query, router, searchParams])

  const fields = useMemo(() => ({ query, setQuery }), [query, setQuery])

  return (
    <SearchContext.Provider value={fields}>{children}</SearchContext.Provider>
  )
}
export default SearchContext
